'use client';
import React, {useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {hideModal} from 'features/modal/slice';
import Modal from 'features/modal/components/Modal/Modal';
import CloseIcon from 'assets/img/icons/close_menu_white.svg';
import SuccessIcon from 'assets/img/icons/success-mark.svg';
import WarningIcon from 'assets/img/icons/alert.svg';

import * as styles from './styles';

let ModalWrapper = (props) => {
    const myRef = useRef();

    const handleClose = () => {
        if (props.handleClose) {
            props.handleClose();

            return;
        }

        props.hideModal();
    };
    const handleClick = (e) => {
        if (e.key && e.key !== 'Escape') return;
        if (myRef.current.contains(e.target)) return;

        handleClose();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        document.addEventListener('keydown', handleClick, false);
        window.addEventListener('popstate', handleClose, false);
        return () => {
            document.removeEventListener('mousedown', handleClick, false);
            document.removeEventListener('keydown', handleClick, false);
            window.removeEventListener('popstate', handleClose, false);
        };
    }, []);

    return (
        <div style={styles.container}>
            <div style={styles.background} />
            <div style={styles.aligner} />
            <div style={styles.outer}>
                <div style={styles.wrapper(props)} ref={myRef}>
                    <div style={styles.header}>
                        <div onClick={handleClose}>
                            <CloseIcon style={styles.close} />
                        </div>
                    </div>
                    <div style={styles.body}>{props.children}</div>
                </div>
            </div>
        </div>
    );
};

export default connect(null, {
    hideModal,
})(ModalWrapper);
