import {get, post, patch, del} from 'core/configureRequest';
import {timeFormat, timeParse} from 'd3-time-format';

import {download} from 'features/transfer/actions';
import {
    include,
    includeChanged,
    shallowIncludeChanged,
    getDetailedError,
    maybeIncludeAnalytics,
    queryStringConverter,
    omit,
    get as getProp,
} from 'utils/helpers';
import {notifyError, notifySuccessWithTimeout} from 'features/notification/slice';
import {selectActiveReview} from 'features/reviews/selectors';
import {EVENT_CATEGORIES} from 'core/constants';
import {get365Days} from 'utils/dates';

const specifierFrom = '%m/%d/%Y';
const specifierTo = '%Y-%m-%d';

export const requestSearchReviews = (filters) => ({
    type: 'REQUEST_SEARCH_REVIEWS',
    filters,
});

export const receiveSearchResults = (reviews, pagination) => ({
    type: 'RECEIVE_REVIEW_RESULTS',
    reviews,
    pagination,
});

export const receiveSearchResultsFailure = (error) => ({
    type: 'RECEIVE_REVIEW_RESULTS_FAILURE',
    error,
});

const requestEditReview = () => ({
    type: 'REQUEST_EDIT_REVIEW',
});

const receiveEditReview = (review) => ({
    type: 'RECEIVE_EDIT_REVIEW',
    review,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Reviews,
        },
    },
});

const receiveEditReviewFailure = (error) => ({
    type: 'RECEIVE_EDIT_REVIEW_FAILURE',
    error,
});

const requestEditReviewStatus = () => ({
    type: 'REQUEST_EDIT_REVIEW_STATUS',
});

const receiveEditReviewStatus = (review) => ({
    type: 'RECEIVE_EDIT_REVIEW_STATUS',
    review,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Reviews,
            label: getProp(review, 'status.name'),
        },
    },
});

const requestDeleteReview = () => ({
    type: 'REQUEST_DELETE_REVIEW',
});

const receiveDeleteReview = (reviewId) => ({
    type: 'RECEIVE_DELETE_REVIEW',
    reviewId,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Reviews,
        },
    },
});

export const setActiveReviewId = (reviewId) => ({
    type: 'SET_ACTIVE_REVIEW_ID',
    reviewId,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Reviews,
        },
    },
});

/**
 * Responses
 */

export const requestCreateResponse = () => ({
    type: 'REQUEST_CREATE_RESPONSE',
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Reviews,
        },
    },
});

export const receiveCreateResponse = (response) => ({
    type: 'RECEIVE_CREATE_RESPONSE',
    response,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Reviews,
        },
    },
});

export const receiveCreateResponseFailure = (error) => ({
    type: 'RECEIVE_CREATE_RESPONSE_FAILURE',
    error,
});

const requestEditResponse = () => ({
    type: 'REQUEST_EDIT_RESPONSE',
});

const receiveEditResponse = (response) => ({
    type: 'RECEIVE_EDIT_RESPONSE',
    response,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Reviews,
        },
    },
});

const receiveEditResponseFailure = (error) => ({
    type: 'RECEIVE_EDIT_RESPONSE_FAILURE',
    error,
});

const requestEditResponseStatus = () => ({
    type: 'REQUEST_EDIT_RESPONSE_STATUS',
});

const receiveEditResponseStatus = (response) => ({
    type: 'RECEIVE_EDIT_RESPONSE_STATUS',
    response,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Reviews,
            label: getProp(response, 'status.name'),
        },
    },
});

const requestDeleteResponse = () => ({
    type: 'REQUEST_DELETE_RESPONSE',
});

const receiveDeleteResponse = (responseId) => ({
    type: 'RECEIVE_DELETE_RESPONSE',
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Reviews,
        },
    },
});

const moveListingType = (arr, listing_type = 'free') => {
    // algo ref: quicksort
    let j = 0; // counter
    for (let i = 0; i < arr.length; i++) {
        //traversing...
        if (arr[i].listing_type !== listing_type) {
            // choosing non-zero elements
            [arr[i], arr[j]] = [arr[j], arr[i]]; // swaping the current element index with that of counter
            j++; // increement counter for the next element
        }
    }
    return arr;
};

export const searchReviews =
    (filters = {}, clear = false) =>
    (dispatch, getState) => {
        dispatch(requestSearchReviews(filters));

        let query;

        if (clear) {
            query = queryStringConverter(filters, true);
        } else {
            let ommitted = omit(getState().reviews.filters, 'activeTab');
            query = queryStringConverter({...ommitted, ...filters}, true);
        }

        return get(`/proxy/api/user_review/${query}`)
            .then((data) => {
                const pagination = {
                    total: data.data.count,
                    count: data.data.search_filter.count,
                    page: data.data.search_filter.page,
                };

                data.data.user_reviews = moveListingType(data.data.user_reviews, 'vip');
                data.data.user_reviews = moveListingType(
                    data.data.user_reviews,
                    'promoted',
                );
                data.data.user_reviews = moveListingType(
                    data.data.user_reviews,
                    'standard',
                );
                data.data.user_reviews = moveListingType(
                    data.data.user_reviews,
                    'claimed',
                );
                data.data.user_reviews = moveListingType(data.data.user_reviews, 'free');

                dispatch(receiveSearchResults(data.data.user_reviews, pagination));

                return data;
            })
            .catch((e) => {
                dispatch(
                    receiveSearchResultsFailure(
                        e && e.message ? e.message : 'Could not fetch reviews',
                    ),
                );

                return Promise.reject(e);
            });
    };

export const editReview = (values) => (dispatch, getState) => {
    dispatch(requestEditReview());

    const updatedValues = shallowIncludeChanged(
        values,
        getState().reviews.results.find(
            (review) => review.review_id === values.review_id,
        ),
    );

    return patch(`/proxy/api/user_review/${values.review_id}/`, updatedValues)
        .then((data) => {
            dispatch(receiveEditReview(data.data.review));

            return data;
        })
        .catch((e) => {
            dispatch(receiveEditReviewFailure());
            dispatch(notifyError(e && e.message ? e.message : 'Could not edit review'));

            return Promise.reject(e);
        });
};

export const editReviewStatus = (reviewId, status) => (dispatch, getState) => {
    dispatch(requestEditReviewStatus());

    return patch(`/proxy/api/user_review/${reviewId}/`, {status})
        .then((data) => dispatch(receiveEditReviewStatus(data.data.review)))
        .catch((e) => {
            dispatchEvent(receiveEditReviewFailure());
            dispatch(
                notifyError(
                    e && e.message ? e.message : 'Could not change review status',
                ),
            );

            return Promise.reject(e);
        });
};

export const deleteReview = (reviewId) => (dispatch, getState) => {
    dispatch(requestDeleteReview());

    return del(`/proxy/api/user_review/${reviewId}/`).then((data) => {
        dispatch(notifySuccessWithTimeout('Review successfully deleted!'));
        dispatch(receiveDeleteReview(data.data.review_id));

        return data;
    });
};

export const flagReview = (reviewId, flaggedReason) => (dispatch, getState) => {
    dispatch(requestEditReviewStatus());

    return patch(`/proxy/api/user_review/${reviewId}/flag/`, {
        status: 'flagged',
        flagged_reason: flaggedReason,
    })
        .then((data) => {
            dispatch(receiveEditReviewStatus(data.data.review));
            return data;
        })
        .catch((e) => {
            dispatch(receiveEditReviewFailure());
            dispatch(notifyError(getDetailedError(e)));
        });
};

export const createResponse = (values) => (dispatch, getState) => {
    dispatch(requestCreateResponse());

    return post('/proxy/api/user_review/official_response/', values)
        .then((data) => {
            dispatch(receiveCreateResponse(data.data.official_response));
            return data;
        })
        .catch((e) => {
            dispatch(
                receiveCreateResponseFailure(
                    e && e.message ? e.message : 'Could not create official response',
                ),
            );
        });
};

export const editResponse = (values) => (dispatch, getState) => {
    dispatch(requestEditResponse(values));

    const activeReview = selectActiveReview(getState());
    const responseId = getProp(activeReview, 'official_response.response_id');

    const valuesToPatch = include(values, [
        'text',
        'author_name',
        'author_email',
        'author_title',
    ]);

    return patch(`/proxy/api/user_review/official_response/${responseId}/`, valuesToPatch)
        .then((data) => {
            dispatch(receiveEditResponse(data.data.official_response));

            return data;
        })
        .catch((e) => {
            dispatch(
                receiveEditResponseFailure(
                    e && e.message
                        ? e.message
                        : 'Could not edit Official Facility Response',
                ),
            );
            return Promise.reject(e);
        });
};

export const editResponseStatus = (review, status) => (dispatch, getState) => {
    dispatch(requestEditResponseStatus());

    const {response_id, text} = review;

    return patch(`/proxy/api/user_review/official_response/${response_id}/`, {
        status,
        text,
    })
        .then((data) => {
            dispatch(receiveEditResponseStatus(data.data.official_response));
            return data;
        })
        .catch((e) => {
            dispatch(
                notifyError(e && e.message ? e.message : 'Could not change OFR status'),
            );
            Promise.reject(e);
        });
};

export const deleteResponse = (responseId) => (dispatch, getState) => {
    dispatch(requestEditReview());

    return del(`/proxy/api/user_review/official_response/${responseId}/`)
        .then((data) => {
            dispatch(receiveEditReview(data.data.review));
            return data;
        })
        .catch((e) => {
            dispatch(receiveEditReviewFailure());
            return Promise.reject(e);
        });
};

export const downloadReviews = () => (dispatch, getState) => {
    const specifier = '%m/%d/%Y';
    let filters = getState().reviews.filters || {};
    if (!filters.listing_id && !filters.account_id && !filters.from) {
        filters = {
            ...filters,
            from: get365Days(specifier),
        };
    }
    const query = queryStringConverter(
        {
            ...filters,
            format: 'csv',
            page: undefined,
            count: undefined,
        },
        true,
    );

    dispatch(
        download('/download', {
            method: 'GET',
            url: '/api/user_review/' + query,
        }),
    );
};
